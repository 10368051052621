import React from 'react';
import NewSidebar from '../new-sidebar';
import Header from '../Common/Header';
import SubadminInvoicesComponent from './SubadminInvoicesComponent';

const SubadminInvoices = () => {
    return (
        <>
            <Header />
            <NewSidebar />
            <div className="content-wrapper admin-membership-setting admin-social-wall">
                <SubadminInvoicesComponent />
            </div>
        </>
    );
}

export default SubadminInvoices;