import React, { useState,useEffect } from "react";
import { Nav, Image } from "react-bootstrap";
import { NavLink,useLocation} from "react-router-dom";
import rightArrow from "../../images/admin/right-arrow-white.svg";
const Sidebar = () => {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownLinks = {
    account: ["/settings/account", "/settings/billing", "/settings/change-password"],
    business: ["/settings/branding", "/settings/company", "/settings/policy-pages"],
    sales: ["/settings/payment","/settings/sales","/settings/un-subscribe","/settings/accounts","/coupons"],
    communication: ["/settings/notifications","/settings/news","/settings/welcome","/email-setting"]
  };
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  useEffect(() => {
    for (const [dropdownName, routes] of Object.entries(dropdownLinks)) {
      if (routes.includes(location.pathname)) {
        setOpenDropdown(dropdownName);
        break;
      }
    }
  }, [location.pathname]);

  return (
    <Nav variant="pills" className="flex-column">
      <div className="dropdown">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => toggleDropdown("account")}
          aria-expanded={openDropdown === "account"}
        >
          <span style={{ fontWeight: openDropdown === "account" ? 700 : 400 }}>My Account</span>
          <i
            className={`text-white right fa fa-angle-right ${
              openDropdown == "account" ? "rotated" : ""
            }`}
            
          />
        </div>
        {openDropdown === "account" && (
          <div className="sub-menu">
            <Nav.Item>
              <NavLink to="/settings/account" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" /> Account
                Details
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/settings/billing" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" /> Billing
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/settings/change-password" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" /> Change
                Password
              </NavLink>
            </Nav.Item>
          </div>
        )}
      </div>

      <div className="dropdown">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => toggleDropdown("business")}
          aria-expanded={openDropdown === "business"}
        >
          <span style={{ fontWeight: openDropdown === "business" ? 700 : 400 }}>My Business</span>
          <i
            className={`text-white right fa fa-angle-right ${
              openDropdown == "business" ? "rotated" : ""
            }`}
            
          />
        </div>
        {openDropdown === "business" && (
          <div className="sub-menu">
            <Nav.Item>
              <NavLink to="/settings/branding" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" />Branding
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/settings/company" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" />Business / Hub Details
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/settings/policy-pages" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" />T&C's,
                Policy, Email Disclaimer
              </NavLink>
            </Nav.Item>
            {/* <Nav.Item>
              <NavLink to="/settings/policy-pages" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" /> 
                Domain
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/settings/policy-pages" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" /> 
                Files
              </NavLink>
            </Nav.Item> */}
          </div>
        )}
      </div>

      <div className="dropdown">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => toggleDropdown("sales")}
          aria-expanded={openDropdown === "sales"}
        >
          <span style={{ fontWeight: openDropdown === "sales" ? 700 : 400 }}>My Sales Settings</span>
          <i
            className={`text-white right fa fa-angle-right ${
              openDropdown == "sales" ? "rotated" : ""
            }`}
            
          />
        </div>
        {openDropdown === "sales" && (
          <div className="sub-menu">
            <Nav.Item>
              <NavLink to="/settings/payment" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" /> Payment
                Settings
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/settings/sales" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" /> Sales Page
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/settings/un-subscribe" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" />{" "}
                Unsubscribe Settings
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/settings/subscribe-settings" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" />{" "}
                Subscribe Settings
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/member-transaction" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" />{" "}
                Member Transaction
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/coupons" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" /> Coupons
              </NavLink>
            </Nav.Item>
          </div>
        )}
      </div>

      <div className="dropdown">
        <div
          className="d-flex justify-content-between align-items-center gap-2"
          onClick={() => toggleDropdown("communication")}
          aria-expanded={openDropdown === "communication"}
        >
          <span style={{ fontWeight: openDropdown === "communication" ? 700 : 400 }}>My Member Communications</span>
          <i
            className={`text-white right fa fa-angle-right ${
              openDropdown == "communication" ? "rotated" : ""
            }`}
            
          />
        </div>
        {openDropdown === "communication" && (
          <div className="sub-menu">
            <Nav.Item>
              <NavLink to="/settings/notifications" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" />{" "}
                Notifications Settings
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/settings/news" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" /> News
                Section
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/settings/welcome" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" /> Welcome
                Page
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/email-setting" className="nav-link">
                <Image src={rightArrow} className="invisible mr-2" /> Email
              </NavLink>
            </Nav.Item>
          </div>
        )}
      </div>
    </Nav>
  );
};

export default Sidebar;
