import React from 'react'
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";

export default function GoBackButton() {
    const navigate = useNavigate();
    return (
        <div className="row w-100 p-4">
            <span
                className="d-flex align-items-center fs-6 pointer w-25"
                style={{ color: "#12aeb4" }}
                onClick={() => navigate(-1)}
            >
                <FontAwesomeIcon
                    icon={faCircleLeft}
                    color="#12aeb4"
                    style={{ height: "25px", marginRight: ".5rem" }}
                />{" "}
                Go Back
            </span>
        </div>
    )
}
