import React from "react";
import { NavLink } from "react-router-dom";
import "./login.css";
import { login } from "../helper/service";
import logo from "../../src/images/logo.png";
import bgImage from "../images/body-bg.svg";
import Loader from '../Loader';

export interface LoginProps {
  history: any;
}

export interface LoginState {
  emailError: any;
  email: any;
  password: any;
  passwordType: any;
  passwordError: any;
  isloading:boolean;
}

class Login extends React.Component<LoginProps, LoginState> {
  state = {
    email: "",
    password: "",
    passwordType: "password",
    emailError: "",
    passwordError: "",
    isloading:false,
  };

  setEmail = (e: { target: { value: any } }) => {
    this.setState({
      email: e.target.value,
    });
  };

  setPassword = (e: { target: { value: any } }) => {
    this.setState({
      password: e.target.value,
    });
  };

  handleSignin = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    let { email, password } = this.state;
    let data = {
      email: email.trim(),
      password: password,
    };
    
    this.setState({isloading:true});
    try{
    if (this.validate()) {
     await login(data);
    }}
    finally{
      this.setState({isloading:false});
    }

  };

  showPassword() {
    if (this.state.passwordType == "text") {
      this.setState({ passwordType: "password" });
    } else {
      this.setState({ passwordType: "text" });
    }
  }

  validate = () => {
    let formIsValid = true;

    if (!this.state.email) {
      formIsValid = false;
      this.setState({ emailError: "*Please Enter Email Address" });
    } else {
      this.setState({ emailError: "" });
    }

    if (!this.state.password) {
      formIsValid = false;
      this.setState({ passwordError: "*Please Enter Password" });
    } else {
      this.setState({ passwordError: "" });
    }

    return formIsValid;
  };

  render() {
    document.body.style.backgroundImage = "url(" + bgImage + ")";
    
    return (
      <> 
      {this.state.isloading && <Loader /> }

      <div className="" onSubmit={this.handleSignin}>
        {/* <form> */}
        {/* <Loader/> */}

        <div id="login" className="d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body text-center">
                    <div className="login-logo">
                      <img
                        src={logo}
                        className="img-fluid mx-auto d-block"
                        alt="logo"
                      ></img>
                    </div>
                    <h2 className="login-title">Welcome</h2>
                    <p className="login-text">
                      to WISDOME, your sharing platform. Helping you connect and
                      engage with your members easily in one place.
                    </p>
                    <p className="login-msg">Sign in</p>
                    <form action="" method="post" className="login-form">
                      <div className="form-group mb-3 text-left">
                        <input
                          type="text"
                          className="form-control input-email"
                          onChange={this.setEmail}
                          placeholder="Enter Email Address"
                        ></input>
                        <span className="errormessage">
                          {this.state.emailError}
                        </span>
                      </div>
                      <div className="form-group mb-3 text-left">
                        <div className="input-group">
                          <input
                            type={this.state.passwordType}
                            className="form-control input-password"
                            onChange={this.setPassword}
                            placeholder="Enter Password"
                          ></input>
                          <div
                            className="input-group-append"
                            onClick={this.showPassword.bind(this)}
                          >
                            <div className="input-group-text">
                              {this.state.passwordType === "password" ? (
                                <span className="fas fa-eye"></span>
                              ) : (
                                <span className="fas fa-eye-slash"></span>
                              )}
                            </div>
                          </div>
                        </div>
                        <span className="errormessage">
                          {this.state.passwordError}
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-6 text-left">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="remember"
                            ></input>
                            <label
                              className="custom-control-label"
                              htmlFor="remember"
                            >
                              Remember Me
                            </label>
                          </div>
                        </div>
                        {/* <div className="col-2 text-right p-0"> */}
                        {/* <a href="/signup" className="register">Signup</a> */}
                        {/* <NavLink to="/signup" className="register">Signup</NavLink> */}
                        {/* <NavLink to="/splash" className="register">Signup</NavLink> */}
                        {/* </div> */}
                        <div className="col-6 text-right">
                          {/* <a href="/forgot-password"  className="forgot-password">I forgot my password</a> */}
                          <NavLink
                            to="/forgot-password"
                            className="forgot-password"
                          >
                            Forgot Password?
                          </NavLink>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button type="submit" className="btn btn-info submit-form-button">
                            Log In
                          </button>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-12">
                          <LoginSocialGoogle
                            client_id="902936489846-a9orp95906nqqusalt6j6u35cl1tktqd.apps.googleusercontent.com"
                            onResolve={({ provider, data }: IResolveParams) => {
                              alert("Login Success");
                              console.log(provider);
                              console.log(data);

                              // setProvider(provider)
                              // setProfile(data)
                            }}
                            onReject={(err) => {
                              console.log(err);
                            }}
                          >
                            <Button variant="google" className="btn-block">
                              <svg
                                aria-hidden="true"
                                className="native svg-icon iconGoogle"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                              >
                                <path
                                  d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18Z"
                                  fill="#4285F4"
                                ></path>
                                <path
                                  d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17Z"
                                  fill="#34A853"
                                ></path>
                                <path
                                  d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07Z"
                                  fill="#FBBC05"
                                ></path>
                                <path
                                  d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3Z"
                                  fill="#EA4335"
                                ></path>
                              </svg>{" "}
                              Log in with Google
                            </Button>
                          </LoginSocialGoogle>

                          <LoginSocialFacebook
                            appId={"410746914344258"}
                            onResolve={({ provider, data }: IResolveParams) => {
                              console.log(provider);
                              console.log(data);
                              //setProvider(provider)
                              //setProfile(data)
                            }}
                            onReject={(err: any) => {
                              console.log(err);
                            }}
                          >
                            {/* <div className="col-12"> <button type="submit" className="btn btn-info">Facebook Login</button></div> 

                            <Button
                              variant="facebook"
                              className="btn-block mt-2"
                            >
                              <svg
                                aria-hidden="true"
                                className="svg-icon iconFacebook"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                              >
                                <path
                                  d="M3 1a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3Zm6.55 16v-6.2H7.46V8.4h2.09V6.61c0-2.07 1.26-3.2 3.1-3.2.88 0 1.64.07 1.87.1v2.16h-1.29c-1 0-1.19.48-1.19 1.18V8.4h2.39l-.31 2.42h-2.08V17h-2.5Z"
                                  fill="#ffffff"
                                ></path>
                              </svg>{" "}
                              Log in with Facebook
                            </Button>
                          </LoginSocialFacebook>
                        </div>
                      </div> */}
                      <div className="row mt-2">
                        <div className="col-12">
                          <NavLink to="/signup" className="register">
                            Sign Up
                          </NavLink>
                        </div>
                      </div>
                      
                      

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </form> */}
      </div>
      </>
    );
  }
}

export default Login;
