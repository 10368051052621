import React from 'react';
import img2 from '../images/company-logo.svg'
//import img2 from '../../src/images/company-logo.svg' 
import calendar from '../images/enduser/calendar (11).svg';
import chat from '../images/enduser/chat (9)-1.svg';
import heart from '../images/enduser/heart (4).svg';
import folder from '../images/enduser/folder (1).svg';
import user from '../images/enduser/user.svg';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';



class NewSidebarPreview extends React.Component {

  componentDidMount(){
    $(document).ready(function() {
      $(".sidebar ul li").click(function () {
        if ($(this).hasClass('menu-is-opening menu-open')) {
          $("li").removeClass("menu-is-opening menu-open");
        } else {
          $(this).addClass("menu-is-opening menu-open");
        }
      });
    });
  }
  

  render() {

    let base64 = localStorage.getItem('image');
    const currentURL = window.location.pathname;
    var bgcolor = localStorage.getItem('bgcolor');
    var membershipName = localStorage.getItem('membershipName');

    var user_id = localStorage.getItem('user_id');

    let base641 = localStorage.getItem('imgsFull');

    if (bgcolor) {
      bgcolor = bgcolor;
    } else {
      bgcolor = "#3F53A528";
    }

    if (base64) {
      var imgs = base64;
    } else {
      var imgs = base641;
    }

    const mystyle = {
      backgroundColor: bgcolor,
      boxShadow: "0px 3px 39px #8d8d8d17",
      PointerEvent:"none",
    };

    const compName = localStorage.getItem('compName');

    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4" style={mystyle}>
        <a className="nav-link collapse-sidebar" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
        {/* Brand Logo */}
        <a href="#" className="brand-link company-info" style={{ backgroundColor: bgcolor, border: 0 }}>
          <div style={{display:"flex"}}>
            <img src={imgs} className="brand-image" alt="Admin Logo" />
            <p className="company-name brand-text">{compName} <small>{membershipName}</small></p>
          </div>
        </a>
        <div className="sidebar">
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-item">
                {/* <a href="dashboard.html" className="nav-link active"> */}
                <a href="#" className={currentURL === "dashboard" ? 'nav-link active  text-white' : 'nav-link  text-white'}>
                  <span className="dashboard nav-icon" />
                  <p>
                    Home
                  </p>
                </a>
                {/* </a> */}
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link  text-white" ClassName={currentURL === "3-step" ? 'nav-link active' : 'nav-link'}>
                  {/* <span className="step nav-icon" /> */}
                  <img src={user} alt="profile-img"  style={{marginRight:"28.5px",width:"20px",height:"20px"}}/>
                  <p>
                    Profile
                  </p>
                </a>
              </li>
              {/* <li className="nav-item">
                <a href="#" className="nav-link" ClassName={currentURL === "members" ? 'nav-link active' : 'nav-link'}>
                  <span className="member nav-icon" />
                  <p>
                    Members
                  </p>
                </a>
              </li> */}


              <li className="nav-item">
                <a href="#" className="nav-link  text-white">
                  <span className="library nav-icon" />
                  <p>
                  Content Library
                    {/* <i className="right fa fa-angle-right" /> */}
                  </p>
                </a>
                {/* <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Content Library</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Video Library</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Audio Library</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Photo Library</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Courses</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Challenges</p>
                    </a>
                  </li>
                </ul> */}
              </li>

              <li className="nav-item">
                <a href="#" className="nav-link  text-white">
                  <span className="courses nav-icon" />
                  <p>Courses</p>
                </a>
              </li>


              <li className="nav-item">
                <a href="#" className="nav-link  text-white" >
                  <span className="challenges nav-icon" />
                  <p>Challenges</p>
                </a>
              </li>

              <li className="nav-item">
                <a href="#" className="nav-link  text-white">
                  <span className="event nav-icon" />
                  <p>Events</p>
                </a>

                
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link  text-white">
                  <span className="socialwall nav-icon" />
                  <p>Social Wall</p>
                </a>
              </li>
              {/* <li className="nav-item">
                <a href="#" className="nav-link">
                  <img src={heart} className="nav-icon img-fluid" alt="sidebar-icon" />
                  <p>Favourites</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <img src={folder} className="nav-icon img-fluid" alt="sidebar-icon" />
                  <p>
                    Info Portal
                    <i className="right fa fa-angle-right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Info Portal</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Info Portal</p>
                    </a>
                  </li>
                </ul>
              </li> */}
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
      </aside>
    )
  }
}

export default NewSidebarPreview;
