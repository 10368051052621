import React,{useEffect, useState} from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import {getUnsubscribeEmailUsers} from '../../helper/service.ts';

import close from "../../images/dashboard/modal-close.svg";

const UnsubscribedListModal = (props) => {
    const [isOpen, setisOpen] = useState(true);
    const [allUsers,setAllUsers] = useState([]);
    const [totalUsers,setTotalUsers] =useState(0);
    const [unsubscribeUsers,setUnsubscribeUser]=useState(0);
    useEffect(()=>{
        // getUnsubscribedUsers();
        setAllUsers(props.unsubsList);
        setUnsubscribeUser(props.unsubsList?.length);
        setTotalUsers(props.recipientCount - props.unsubsList?.length);
    },[])

    const getUnsubscribedUsers = async() => {
        await getUnsubscribeEmailUsers()
        .then(data=>{
            setAllUsers(data.result.users);
            setUnsubscribeUser(data.result.users.length);
            setTotalUsers(data.result.totalUser);
        })
        .catch(error=>{
            console.log("error",error);
        })
    }              

    const handleCloseModal = () => {
        setisOpen(false);
        props.onClose();
    };
  return (
    <Modal 
    className="wis-modal modal-wide email-detail-modal"
    show={isOpen}
    onHide={handleCloseModal}
    backdrop="static">
    <ModalHeader>
        <h5 className="modal-title pl-0 text-center">
            Unsubscribed List
        </h5>
        <button
            type="button"
            className="close ml-5"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleCloseModal}
        >
        <span aria-hidden="true">
            <img src={close} />
        </span>
        </button>
    
    </ModalHeader>
    <ModalBody>
        <div className='d-flex justify-content-center align-items-center mb-4 flex-column'>
            <div className='form-group'>
                <label>Total Unsubscribed: </label>
                <span className='fs-6 mt-2'> {unsubscribeUsers}</span>
            </div>
            <div className='form-group'>
                <label>Total Subscribed: </label>
                <span className='fs-6 mt-2'> {totalUsers}</span>
            </div>
        </div>
        <div className='d-flex justify-content-left'>
            <table className='w-100'>
                <tr className='mb-2'>
                    <td>Email</td>
                    {/* <td>Plan</td> */}
                    <td className='border-right-0'>Date Unsubscribed</td>
                </tr>
                {allUsers.length > 0 && allUsers.map(user =>{
                    let formattedDate=''
                    if(user?.unsubscribeDate) {
                        let newDate = new Date(user?.unsubscribeDate)
                        formattedDate= newDate.toISOString().split("T")[0];
                    }else{
                        let newDate = new Date(user?.updatedAt)
                        formattedDate= newDate.toISOString().split("T")[0];
                    }

                return(
                    <tr>
                        <td>{user?.memberEmail}</td>
                        {/* <td>{user?.subsPlan}</td> */}
                        <td className='border-right-0'>{formattedDate}</td>
                    </tr>
                )})}
            </table>
        </div>
    
    </ModalBody>
    </Modal>
  )
}

export default UnsubscribedListModal