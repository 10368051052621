import {combineReducers } from 'redux';
import loginReducer from './loginReducer';
// import socketData from './socketReducer';
import registerReducer from './registerReducer';
import fechContactReducer from './fechContactReducer';
import lessonReducer from './lessonReducer';
const rootReducer = combineReducers({
    loginReducer,
    registerReducer,
    fechContactReducer,
    lessonReducer,
    //socketData
    })

export default rootReducer;