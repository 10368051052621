import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment-timezone';
import * as Yup from "yup";
import SunRichTextEditor from "../RichTextEditor/SunRichTextEditor.js";
import Header from "../Common/Header";
import NewSidebar from "../new-sidebar";
import ConfirmationModal from "../Setting/ConfirmationModal";
import SuccessPopup from "../Setting/Sucesspopup";

import { userInfo, createEmailSettingForMember, getInviteeUsers, getRecentCourses, getAllMemberThatBuyMembership, getAllsubscription, getSubscribersList, getAllEvents } from '../../helper/service.ts';
import useMembersPageStore from "../../stores/useMembersPageStore";

import searchicon from "../../images/admin/search-dark.svg";
import infoIcon from "../../images/dashboard/ic_error.svg";
import Loader from "../../Loader.js";
import { getGmtOffset } from "../../helper/dateHelper";
import { Tooltip } from "@mui/material";
import UserReportModalList from "./UserReportModalList.js";
import GoBackButton from "../Common/Buttons/GoBackButton.jsx";

const content = {
  title: "Create Your Email",
  description: "Deliver messages to your members email inbox. Member can reply to you directly via email to your company email that you have set up. Member will recieve email from your company email address.",
  recipient: {
    title: "RECIPIENTS",
    instructions: [
      "Minimum 1 Recipient Type is required",
      "Choose as many recipients from the groupings below."
    ]
  }
};

const CreateEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { emailSubject, emailMsg } = location.state || {};

  const [subject, setSubject] = useState(emailSubject || '');
  const [msgContent, setMsgContent] = useState(emailMsg || '');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [isSchedule, setIsSchedule] = useState(false);
  const [attachments, setAttachments] = useState(null);
  const [attachmentsName, setAttachmentsName] = useState('');
  const [broadCast, setBroadCast] = useState(false);
  const [selectedAccessLevels, setSelectedAccessLevels] = useState([]);
  const [selectedCourseIds, setSelectedCourseIds] = useState([]);
  const [selectedIndividuals, setSelectedIndividuals] = useState([]);
  const [selectedSubscribers, setSelectedSubscribers] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedEventMembers, setSelectedEventMembers] = useState([]);

  const [accessLevels, setAccessLevels] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [subscriberList, setSubscriberList] = useState([]);
  const [courseTempList, setCourseTempList] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [eventTempList, setEveTempList] = useState([]);

  const [individualData, setIndividualData] = useState('');
  const [subscribersData, setSubscribersData] = useState('');

  const [showWarningModal, setWarningModal] = useState(false);
  const [isLoading, setIsLoeading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [mdlMessage, setMdlMessage] = useState('');
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [showUserReportModal, setShowUserReportModal] = useState(false);
  const [recipientUsers, setRecipientUsers] = useState(null);
  const [sentEmailDate, setSentEmailDate] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [mergedList, setMergedList] = useState([]);
  const [renderedData, setRenderedData] = useState([]);

  const setMemberSearchQuery = useMembersPageStore((state) => state.setMemberSearchQuery);
  const memberSearchQuery = useMembersPageStore(
    (state) => state.memberSearchQuery
  );
  useEffect(() => {
    getSubscriptions();
    getIndividualUsers();
    fetchRecentCourses();
    getAllSubscribers();
    getEvents();
    handleDefaultContent();
  }, []);

  useEffect(() => {
    subscriptionList(accessLevels);
  }, [selectedAccessLevels]);

  useEffect(() => {
    individualMemberList(invitedMembers);
  }, [selectedIndividuals]);

  useEffect(() => {
    courseMemberList(courseList);
  }, [selectedCourseIds]);

  useEffect(() => {
    individualSubscriberList(subscriberList);
  }, [selectedSubscribers]);

  useEffect(() => {
    renderMergedList([...mergedList]);
  }, [mergedList]);

  const filteringArrays = (array, type = "add") => {
    if (type === "add") {
      mergeUniqueItems(array);
    } else {
      removeFromMergedItems(array);
    }
  };

  const handleDefaultContent = () => {
    {
      const userId = localStorage.getItem('user_id');
      userInfo(userId).then(data => {
        let content = '';
        if (data?.result?.validUser) {
          const user = data?.result?.validUser;

          const hasSettings = user?.emailSettings?.length > 0;
          if (hasSettings) {
            const emailSettings = user.emailSettings[0]?.emailFooter;
            if (emailSettings) {
              content += `<p></br></br>${emailSettings}</p>`;
            }
          }

          if (user.emailDisclaimer) {
            content += `<p></br>${user.emailDisclaimer}</p>`;
          }
          setMsgContent(content);
        }
      }
      )
    }
  }

  const mergeUniqueItems = (...arrays) => {
    const newList = [...arrays.flat(Infinity)];

    setMergedList((prevMerged) => {
      const existingItemsMap = new Map(prevMerged.map((item) => [item.id, item]));

      newList.forEach((item) => {
        if (existingItemsMap.has(item.id)) {
          existingItemsMap.get(item.id).count += 1;
        } else {
          const newItem = { ...item, count: 1 };
          existingItemsMap.set(item.id, newItem);
        }
      });
      const updatedMerged = [...existingItemsMap.values()];
      return updatedMerged;
    });
  };

  const handleCheckboxChange = (e, id) => {
    setMergedList((prevMerged) =>
      prevMerged.map((existingItem) =>
        existingItem.id === id
          ? { ...existingItem, isCheck: e.target.checked }
          : existingItem
      )
    );
  };

  const removeFromMergedItems = (itemId) => {
    setMergedList((prevMerged) => {
      if (Array.isArray(itemId)) {
        return prevMerged.map(item => {
          if (itemId.includes(item.id) && item.count > 0) {
            return { ...item, count: item.count - 1 };
          }
          return item;
        }).filter(item => item.count > 0);
      } else {
        return prevMerged.map(item => {
          if (item.id === itemId && item.count > 0) {
            return { ...item, count: item.count - 1 };
          }
          return item;
        }).filter(item => item.count > 0);
      }
    });
  };

  const renderMergedList = (list) => {
    const datas = list?.map((item) => {
      const name = item.name || '';
      const email = item.email || '';
      const displayName = name?.length > 25 ? `${name?.substring(0, 20)}...` : name;

      return (
        <div className="access-level mb-1 border-bottom-0 p-0" key={item.id} style={{ fontSize: '14px' }}>
          <Tooltip title={email} placement="left-start">
            <span className="float-left text-capitalize">{displayName?.length > 25 ? `${displayName.substring(0, 20)}...` : displayName}</span>
          </Tooltip>
          <div className="custom-control custom-switch float-right">
            <input
              type="checkbox"
              checked={item.isCheck}
              className="custom-control-input"
              id={`merged-${item.id}`}
              onChange={(e) => {
                e.stopPropagation();
                handleCheckboxChange(e, item.id)
              }}
            />
            <label
              className="custom-control-label cursor-pointer"
              htmlFor={`merged-${item.id}`}
            />
          </div>
        </div>
      );
    });
    setRenderedData(datas);
  };

  async function fetchRecentCourses() {
    const datas = await getRecentCourses();

    if (datas?.result?.r1) {
      var currentDate = new Date();
      const filteredResults = datas.result.r1.filter(item => {
        if (!item.schedule && item.tUser > 0) {
          return true;
        }

        const originalDate = new Date(item.schedule);
        const selectedTimeZone = localStorage.getItem('websiteTimeZone');
        const convertedDate = originalDate.toLocaleString('en-US', { timeZone: selectedTimeZone, hour12: true });

        var scheduleDate = new Date(convertedDate);
        return (scheduleDate <= currentDate && item.tUser > 0);
      });

      setCourseList(filteredResults);
      setCourseTempList(filteredResults);
      // courseMemberList(filteredResults);
    }
  }

  async function getIndividualUsers() {
    await getInviteeUsers().then(data => {
      individualMemberList(data.result);
      setInvitedMembers(data.result);
    })
  }

  async function getSubscriptions() {
    getAllsubscription().then((res) => {
      let newArray = [];
      res.result.map((item) => {
        getAllMemberThatBuyMembership(item._id).then((i) => {
          item.memberCount = i.result.validSubs?.length;

          newArray.push(item);
          newArray = newArray.sort((a, b) => {
            const dateA = new Date(a.name);
            const dateB = new Date(b.name);
            return dateA - dateB;
          });
          // subscriptionList(newArray);
          const filteredByCount = newArray.filter((ele) => ele.memberCount > 0);
          setAccessLevels(filteredByCount);
        });
      });
    });
  }

  const getAllSubscribers = async () => {
    await getSubscribersList().then(data => {
      const emailCount = new Map();
      const filteredList = [];

      data.result.forEach(item => {
        if (item.email) {
          emailCount.set(item.email, (emailCount.get(item.email) || 0) + 1);
          if (emailCount.get(item.email) <= 2) {
            filteredList.push(item);
          }
        }
      });

      individualSubscriberList(filteredList);
      setSubscriberList(filteredList);
    });
  };

  const getEvents = async () => {
    await getAllEvents().then(data => {
      const filteredEvents = data.result.filter((ele) => ele?.audience?.length > 0);
      setEventsList(filteredEvents);
      setEveTempList(filteredEvents);
    })
  }

  const subscriptionList = (accessLevels) => {
    if (accessLevels) {
      const filteredByCount = accessLevels.filter((ele) => ele.memberCount > 0);
      const datas = filteredByCount.map(function (accessLevel) {
        var checked = false;

        const found = selectedAccessLevels.find((ele) => {
          if (ele === accessLevel._id) {
            return true;
          } else {
            return false;
          }
        });

        if (found) {
          checked = true;
        }
        return (
          <div className="access-level mb-1" key={accessLevel._id}>
            <span className='float-left text-capitalize'>{accessLevel?.tier?.length > 25 ? `${accessLevel.tier.substring(0, 20)}...` : accessLevel.tier}{accessLevel.memberCount > 0 ? ` (${accessLevel.memberCount})` : ''}</span>
            <div className="custom-control custom-switch float-right">
              <input
                type="checkbox"
                checked={checked}
                className="custom-control-input"
                id={`accessLevel-${accessLevel._id}`}
                name={`accessLevel-${accessLevel._id}`}
                onChange={() => selectAccessLevel(accessLevel)}
                disabled={accessLevel.memberCount === 0}
              />
              <label
                className="custom-control-label cursor-pointer"
                htmlFor={`accessLevel-${accessLevel._id}`}
              />
            </div>
          </div>
        );
      });
      // setSubscriptionData(datas);
    }
  };

  const selectAccessLevel = (level) => {
    const tempLevels = [...selectedAccessLevels];
    const tempSelectedMembers = [...selectedMembers];
    const subadminId = localStorage.getItem("user_id");

    const existingLevelIndex = tempLevels.indexOf(level._id);

    if (existingLevelIndex === -1) {

      tempLevels.push(level._id);
      let newlyAddedMembers = [];

      invitedMembers.forEach((member) => {
        const isSubadminIncluded = member.subadminId === subadminId;
        const hasMatchingSubscription = member.subscriptionId.some(
          (subscription) => subscription?.subsId?._id === level._id
        );

        if (isSubadminIncluded && hasMatchingSubscription) {
          const userDetails = {
            id: member._id,
            name: `${member.firstName} ${member.lastName}`,
            email: member.email,
            isCheck: true,
            count: 1,
          };
          newlyAddedMembers.push(userDetails);

          const existingUserIndex = tempSelectedMembers.findIndex(
            (m) => m.id === member._id
          );

          if (existingUserIndex === -1) {
            tempSelectedMembers.push(userDetails);
          }
        }
      });

      setSelectedMembers(tempSelectedMembers);
      if (newlyAddedMembers.length > 0) {
        filteringArrays(newlyAddedMembers, "add");
      }
    } else {

      tempLevels.splice(existingLevelIndex, 1);

      const updatedSelectedMembers = tempSelectedMembers.filter((member) => {

        const invitedMember = invitedMembers.find((invited) => invited._id === member.id);

        if (!invitedMember) return true;

        const hasSameLevel = invitedMember.subscriptionId.some(
          (subscription) => subscription?.subsId?._id === level._id
        );

        return !hasSameLevel;
      });

      setSelectedMembers(updatedSelectedMembers);
      const removeArr = tempSelectedMembers
        .filter((member) => !updatedSelectedMembers.includes(member))
        .map((member) => member.id);
      filteringArrays(removeArr, "remove");
    }
    setSelectedAccessLevels([...tempLevels]);
    subscriptionList(tempLevels);
  };

  const individualMemberList = (membersData) => {
    if (membersData) {
      const datas = membersData.map(function (member) {
        var checked = false;
        const found = selectedIndividuals.find((ele) => {
          if (ele.id === member._id) {
            return true;
          } else {
            return false;
          }
        });

        if (found) {
          checked = true;
        }
        const fullName = `${member.firstName} ${member.lastName}`
        if (!member.unsubscribeEmailStatus) {
          return (
            <div className="access-level mb-1" key={member._id}>
              <Tooltip title={member.email} placement="right-start">
                <span className="float-left text-capitalize">{fullName?.length > 25 ? `${fullName.substring(0, 20)}...` : fullName}</span>
              </Tooltip>
              <div className="custom-control custom-switch float-right">
                <input
                  type="checkbox"
                  checked={checked}
                  className="custom-control-input"
                  id={`individual-${member._id}`}
                  name={`individual-${member._id}`}
                  onChange={(e) => {
                    e.stopPropagation();  // Prevents event bubbling
                    selectIndividualMember(member);
                  }}
                />
                <label
                  className="custom-control-label cursor-pointer"
                  htmlFor={`individual-${member._id}`}
                />
              </div>
            </div>
          )
        }
      });
      setIndividualData(datas);
    }
  }

  const selectIndividualMember = (member) => {
    const tempMembers = [...selectedIndividuals];
    let newMember = [];
    const memberDetails = {
      id: member._id,
      name: `${member.firstName} ${member.lastName}`,
      email: member.email,
      isCheck: true,
      count: 1,
    };

    const existingMemberIndex = tempMembers.findIndex((m) => m.id === member._id);

    if (existingMemberIndex === -1) {
      tempMembers.push(memberDetails);
      newMember.push(memberDetails);
      filteringArrays(newMember, "add");
    } else {
      tempMembers.splice(existingMemberIndex, 1);
      filteringArrays(member._id, "remove");
    }

    setSelectedIndividuals(tempMembers);
    individualMemberList(invitedMembers);
  }

  const courseMemberList = (coursesData) => {
    if (coursesData) {
      return coursesData.map(function (course) {
        var checked = false;

        const found = selectedCourseIds.find((ele) => {
          if (ele === course._id) {
            return true;
          } else {
            return false;
          }
        });

        if (found) {
          checked = true;
        }
        return (
          <div className="access-level mb-1" key={course._id}>
            <span className='float-left text-capitalize'>{course.title.length > 25 ? `${course.title.substring(0, 20)}...` : course.title}{` (${course.tUser})`}</span>
            <div className="custom-control custom-switch float-right">
              <input
                type="checkbox"
                checked={checked}
                className="custom-control-input"
                id={`course-${course._id}`}
                name={`course-${course._id}`}
                onChange={() => selectCourseMember(course)}
              />
              <label
                className="custom-control-label cursor-pointer"
                htmlFor={`course-${course._id}`}
              />
            </div>
          </div>
        )
      });
    }
  }

  const selectCourseMember = (course) => {
    const tempCoursesId = [...selectedCourseIds];
    const tempStudentDetails = [...selectedStudent];

    const courseIndex = tempCoursesId.indexOf(course._id);

    if (courseIndex === -1) {
      tempCoursesId.push(course._id);
      let newlyAddedStudents = [];
      course.students.forEach((studentId) => {
        const student = invitedMembers.find((user) => user._id === studentId);
        if (student) {
          const studentIndex = tempStudentDetails.findIndex((s) => s.id === studentId);
          const newStudent = {
            id: student._id,
            name: `${student.firstName} ${student.lastName}`,
            email: student.email,
            isCheck: true,
            count: 1,
          };
          newlyAddedStudents.push(newStudent);
          if (studentIndex === -1) {
            tempStudentDetails.push(newStudent);
          }
        }
      });
      if (newlyAddedStudents.length > 0) {
        filteringArrays(newlyAddedStudents, "add");
      }
    } else {
      tempCoursesId.splice(courseIndex, 1);

      course.students.forEach((studentId) => {
        const studentIndex = tempStudentDetails.findIndex((student) => student.id === studentId);
        if (studentIndex !== -1) {
          tempStudentDetails.splice(studentIndex, 1);
        }
      });
      const removeArr = course.students.filter(
        (studentId) => !tempStudentDetails.some((member) => member.id === studentId)
      );
      filteringArrays(removeArr, "remove");
    }
    setSelectedCourseIds(tempCoursesId);
    setSelectedStudent(tempStudentDetails);
  };

  const individualSubscriberList = (membersData) => {
    if (membersData) {
      const datas = membersData.map(function (member) {
        var checked = false;
        const found = selectedSubscribers.find((ele) => {
          if (ele.id === member._id) {
            return true;
          } else {
            return false;
          }
        });

        if (found) {
          checked = true;
        }
        const fullName = `${member.name}`
        return (
          <div className="access-level mb-1" key={member._id}>
            <Tooltip title={member.email} placement="right-start">
              <span className="float-left text-capitalize">{fullName?.length > 25 ? `${fullName.substring(0, 20)}...` : fullName}</span>
            </Tooltip>
            <div className="custom-control custom-switch float-right">
              <input
                type="checkbox"
                checked={checked}
                className="custom-control-input"
                id={`subscriber-${member._id}`}
                name={`subscriber-${member._id}`}
                onChange={() => selectSubscriber(member)}
              />
              <label
                className="custom-control-label cursor-pointer"
                htmlFor={`subscriber-${member._id}`}
              />
            </div>
          </div>
        )
      });
      setSubscribersData(datas);
    }
  }

  const selectSubscriber = (member) => {
    const tempMembers = [...selectedSubscribers];
    let newSubscriber = [];
    const memberDetails = {
      id: member._id,
      name: member.name,
      email: member.email,
      isCheck: true,
      count: 1,
    };

    const existingMemberIndex = tempMembers.findIndex((m) => m.id === member._id);

    if (existingMemberIndex === -1) {
      tempMembers.push(memberDetails);
      newSubscriber.push(memberDetails);
      filteringArrays(newSubscriber, "add");
    } else {
      tempMembers.splice(existingMemberIndex, 1);
      filteringArrays(member._id, "remove");
    }
    setSelectedSubscribers(tempMembers);
    individualSubscriberList(subscriberList);
  }

  const selectEventMember = (event) => {
    const tempEvent = [...selectedEvents];
    const tempStudentDetails = [...selectedEventMembers];

    const eventIndex = tempEvent.indexOf(event._id);

    if (eventIndex === -1) {
      tempEvent.push(event._id);
      let newlyAddedStudents = [];
      event.audience.forEach((user) => {
        const student = invitedMembers.find((member) => member._id === user.user);
        if (student) {
          const studentIndex = tempStudentDetails?.findIndex((stud) => stud?.id === student._id);
          const newStudent = {
            id: student._id,
            name: `${student.firstName} ${student.lastName}`,
            email: student.email,
            isCheck: true,
            count: 1,
          };
          newlyAddedStudents.push(newStudent);
          if (studentIndex === -1) {
            tempStudentDetails.push(newStudent);
          }
        }
      });
      if (newlyAddedStudents.length > 0) {
        filteringArrays(newlyAddedStudents, "add");
      }
    } else {
      tempEvent.splice(eventIndex, 1);

      event?.audience?.forEach((user) => {
        const studentIndex = tempStudentDetails.findIndex((student) => student.id === user.user);
        if (studentIndex !== -1) {
          tempStudentDetails.splice(studentIndex, 1);
        }
      });
      const removeArr = event.audience
        .filter((student) =>
          !tempStudentDetails.some((member) => member.id === student.user)
        )
        .map((student) => student.user);

      filteringArrays(removeArr, "remove");
    }
    setSelectedEvents(tempEvent);
    setSelectedEventMembers(tempStudentDetails);
  };

  const resetStates = () => {
    setIsLoeading(false);
    setSubject('');
    setMsgContent('');
    setDate(new Date());
    setIsSchedule(false);
    setAttachments('');
    setAttachmentsName('');
    setBroadCast(false);
    setSelectedAccessLevels([]);
    setSelectedCourseIds([]);
    setSelectedIndividuals([]);
    setSelectedSubscribers([]);
    setMergedList([]);
    setSelectedEventMembers([]);
  }

  const countDescriptionWords = (text) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = text;

    const images = tempDiv.querySelectorAll('img');
    images.forEach(img => {
      img.outerHTML = 'X';
    });
    const videos = tempDiv.querySelectorAll('iframe');
    videos.forEach(vid => {
      vid.outerHTML = 'X';
    })
    const audios = tempDiv.querySelectorAll('audio');
    audios.forEach(aud => {
      aud.outerHTML = 'X';
    })
    return tempDiv.innerText.trim().length;
  };

  const handlSunRichtextEditorChange = (content) => {
    const count = countDescriptionWords(content);
    // if (count > 2000) {
    //   setWarningModal(true);
    //   setMdlMessage("No more than 2000 characters allowed");
    //   setModalTitle("Action Required");
    //   setModalType("warning");
    //   return;
    // } else 
    if (count !== 0) {
      setMsgContent(content);
    }

  };

  const handleScheduleDateChange = (date) => {
    setDate(date);
  };

  const onResourcesChange = (event) => {

    if (event?.target?.files?.[0]?.size > 50e6) {
      setMdlMessage("Please upload a file smaller than 50 MB.");
      setWarningModal(true);
      setModalType("error");
      setModalTitle("Action Required");
      event.target.value = "";
      return;
    } else {
      if (event.target.files) {
        setAttachments(event?.target?.files?.[0] ?? '');
        setAttachmentsName(event.target.value);
      }
    }
  };

  const filteredMemberList = (memberList) => {
    if (!Array.isArray(memberList)) return [];
    if (!memberSearchQuery) return memberList;

    const result = memberList.filter((member) => {
      const name = `${member.firstName} ${member.lastName}`;
      return (
        name.toLowerCase().includes(memberSearchQuery.toLowerCase()) ||
        member.email.toLowerCase().includes(memberSearchQuery.toLowerCase())
      );
    });

    return result;
  };

  const searchMembers = (event) => {
    event.preventDefault();

    if (event.target.value !== "") {
      setMemberSearchQuery(event.target.value);
      const filteredMember = filteredMemberList(invitedMembers);
      individualMemberList(filteredMember);
    } else {
      individualMemberList(invitedMembers);
    }
  }

  const searchCourses = (event) => {
    event.preventDefault();
    const { value } = event.target;

    if (value !== '') {
      const filteredResult = courseTempList.filter((ele) =>
        ele.title.toLowerCase().includes(value.toLowerCase())
      );
      setCourseList(filteredResult);
    } else {
      setCourseList(courseTempList);
    }
  };

  const searchSubscribers = (event) => {
    event.preventDefault();
    const { value } = event.target;
    if (value !== '') {
      const filteredResult = subscriberList.filter((ele) =>
        ele.name.toLowerCase().includes(value.toLowerCase())
      );
      individualSubscriberList(filteredResult);
    }
    else {
      individualSubscriberList(subscriberList);
    }
  }

  const searchEvents = (event) => {
    event.preventDefault();
    const { value } = event.target;
    if (value !== '') {
      const filteredResult = eventTempList.filter((ele) =>
        ele.name.toLowerCase().includes(value.toLowerCase())
      );
      setEventsList(filteredResult);
    }
    else {
      setEventsList(eventTempList);
    }
  }

  const submitForm = async (event) => {

    event.preventDefault();
    setIsLoeading(true);
    let formattedDate = "";
    let newformattedDate;

    if (date && time && isSchedule) {
      let timeZoneSelected = localStorage.getItem("websiteTimeZone");
      const onlyDate = new Date(date);
      const year = onlyDate.getFullYear();
      const month = onlyDate.getMonth() + 1;
      const day = onlyDate.getDate();
      const scheduleDateOnly = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""
        }${day}`;
      const scheduleDate = `${scheduleDateOnly}T${time}:00`;
      const utcDateTime = moment
        .tz(scheduleDate, `${timeZoneSelected}`)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss");
      // humanize with moment schedule date
      newformattedDate = moment(scheduleDate).format("MMMM Do YYYY, h:mm a");
      formattedDate = utcDateTime;
    } else if (!isSchedule) {
      formattedDate = "";
    }

    const selectedIds = mergedList?.length > 0
      ? mergedList.filter(item => item.isCheck).map(item => item.id)
      : [];
    const data = {
      emailSubject: subject,
      emailMessage: msgContent,
      emailSchedule: formattedDate,
      // memberAccessLevel: selectedAccessLevels,
      // courseIds: selectedCourseIds,
      // individuals: selectedIndividuals,
      files: attachments,
      // usersIdslength: selectedAccessLevels.length + selectedCourseIds.length + selectedIndividuals.length,
      usersIdslength: selectedIds?.length,
      selectedIds: selectedIds,
    };

    const validationSchema = Yup.object().shape({
      emailSubject: Yup.string().required("Email Subject is required"),
      emailMessage: Yup.string().required("Email Content is required"),
      usersIdslength: Yup.number()
        .min(1, "At least one recipient is needed among the four above")
        .required("At least one recipient is needed among the four above"),
    });



    validationSchema
      .validate(data)
      .then((validData) => {
        createEmailSettingForMember(validData).then(res => {
          if (res.status === 200) {
            if (res?.message === "LIMIT_REACHED") {
              setWarningModal(true);
              setIsLoeading(false);
              setModalType('error');
              setModalTitle('Error!!');
              setMdlMessage("Your email limit has exceeded");
            }
            else if (formattedDate == "") {
              if (res?.users?.length > 0) {
                setRecipientUsers(res.users);
              }
              setSentEmailDate(res?.sentDate?.split('T')[0]);
              setShowUserReportModal(true);
              setEmailMessage("Email Sent Successfully to These Users")
            }
            else {
              setIsSuccess(true);
              setMdlMessage(
                "You have successfully scheduled your email to send on: " + `${newformattedDate}`
              );
            }

            resetStates();
          } else {
            setWarningModal(true);
            setModalType('info');
            setModalTitle('FYI');
            setMdlMessage(`${res.message}`);
          }
        }).catch((error) => {
          setIsLoeading(false);
          setWarningModal(true);
          setModalType('error');
          setModalTitle('Error!!');
          setMdlMessage(`${error}`);
        })
      }).catch((error) => {
        var errMsg = `${error}`;
        errMsg = errMsg.split(':')[1];
        setIsLoeading(false);
        setWarningModal(true);
        setModalType('error');
        setModalTitle('Error!!');
        setMdlMessage(`${errMsg}`);
      })
  }

  const handleCloseReportModal = () => {
    setShowUserReportModal(false)
    setRecipientUsers(null);
  }
  return (
    <>
      {showUserReportModal &&
        <UserReportModalList recipientUsers={recipientUsers} sentEmailDate={sentEmailDate}
          handleCloseReportModal={handleCloseReportModal} emailMessage={emailMessage} />
      }
      {isLoading && <Loader />}
      {isSuccess && <SuccessPopup message={mdlMessage} closeSuccessModel={() => { navigate('/email') }} />}
      {showWarningModal &&
        <ConfirmationModal
          type={modalType}
          title={modalTitle}
          message={mdlMessage}
          onConfirm={() => { setModalTitle(''); setModalType(''); setMdlMessage(''); setWarningModal(false) }}
          onCancel={() => { setModalTitle(''); setModalType(''); setMdlMessage(''); setWarningModal(false) }}
        />}
      <Header />
      <NewSidebar />
      <div className="content-wrapper admin-courses">
        <div className="content-header mb-4 email-creation">
          <GoBackButton />
          <section className="email-messaging">
            <div className="content-fluid px-3 py-2">
              <div className="row mt-4 p-2">
                <div className="col-lg-6 col-md-8 col-sm-10">
                  <h2 className="title">{content.title}</h2>
                  <div className="text">
                    <p>
                      {content.description}
                    </p>
                  </div>
                  <div className="mb-4  ">
                    <h2 className="heading">{content.recipient.title}</h2>
                    <ul>
                      {content.recipient.instructions.map((instruction, index) => (
                        <li key={index}><small>{instruction}</small></li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 d-flex justify-content-evenly pr-2 gap-2 flex-wrap mb-4">
                  <div className="scroll" style={{ width: "30%" }}>
                    <div className="form-group mb-0 mt-1">
                      <label>Access Level</label>
                      <div
                        className=""
                        style={{ paddingBottom: "0", border: "none", height: '207px' }}
                      >
                        <div className="access d-flex flex-column"
                          style={{ height: '207px' }} >
                          {/* {subscriptionData} */}
                          {accessLevels && accessLevels?.map(function (accessLevel) {
                            var checked = false;

                            const found = selectedAccessLevels.find((ele) => {
                              if (ele === accessLevel._id) {
                                return true;
                              } else {
                                return false;
                              }
                            });

                            if (found) {
                              checked = true;
                            }
                            return (
                              <div className="access-level mb-1" key={accessLevel._id}>
                                <span className='float-left text-capitalize'>{accessLevel?.tier?.length > 25 ? `${accessLevel.tier.substring(0, 20)}...` : accessLevel.tier}{accessLevel.memberCount > 0 ? ` (${accessLevel.memberCount})` : ''}</span>
                                <div className="custom-control custom-switch float-right">
                                  <input
                                    type="checkbox"
                                    checked={checked}
                                    className="custom-control-input"
                                    id={`accessLevel-${accessLevel._id}`}
                                    name={`accessLevel-${accessLevel._id}`}
                                    onChange={() => selectAccessLevel(accessLevel)}
                                    disabled={accessLevel.memberCount === 0}
                                  />
                                  <label
                                    className="custom-control-label cursor-pointer"
                                    htmlFor={`accessLevel-${accessLevel._id}`}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <span className="errormessage"></span>
                    </div>
                    <div className="form-group mb-0">
                      <div
                        className=""
                        style={{ paddingBottom: "0", border: "none" }}
                      >
                        {/* <div className="access d-flex mt-2 p-0 align-items-center justify-content-center" style={{height:'50px'}}>
                        <div className="access-level" key={""}>
                            <div className="custom-control custom-switch float-left">
                                <input
                                type="checkbox"
                                checked={broadCast}
                                className="custom-control-input"
                                id={"broadCast"}
                                name={"broadCast"}
                                onChange={()=>setBroadCast(prev=>!prev)}
                                />
                                <label
                                className="custom-control-label cursor-pointer"
                                htmlFor={"broadCast"}
                                />
                            </div>
                            <span className="float-right small mt-1">Broadcast message on Member Homepage</span>
                        </div>
                      </div> */}
                      </div>
                      <span className="errormessage"></span>
                    </div>
                  </div>
                  <div className="scroll" style={{ width: "30%" }}>
                    <label>Students in Courses</label>
                    <div className="search-members">
                      <input
                        type="text"
                        name="searchCourse"
                        className="form-control"
                        placeholder="Search courses"
                        onChange={searchCourses}
                      />
                      <span className="search-icon">
                        <img src={searchicon} />
                      </span>
                    </div>
                    <div className="access d-flex flex-column mt-3">
                      {courseList?.map(function (course) {
                        var checked = false;

                        const found = selectedCourseIds.find((ele) => {
                          if (ele === course._id) {
                            return true;
                          } else {
                            return false;
                          }
                        });

                        if (found) {
                          checked = true;
                        }
                        return (
                          <div className="access-level mb-1" key={course._id}>
                            <span className='float-left text-capitalize'>{course.title.length > 25 ? `${course.title.substring(0, 20)}...` : course.title}{` (${course.tUser})`}</span>
                            <div className="custom-control custom-switch float-right">
                              <input
                                type="checkbox"
                                checked={checked}
                                className="custom-control-input"
                                id={`course-${course._id}`}
                                name={`course-${course._id}`}
                                onChange={() => selectCourseMember(course)}
                              />
                              <label
                                className="custom-control-label cursor-pointer"
                                htmlFor={`course-${course._id}`}
                              />
                            </div>
                          </div>
                        )
                      })}
                      {/* {courseMemberList(courseList)} */}
                    </div>
                  </div>
                  <div className="scroll" style={{ width: "30%" }}>
                    <label>Event Registrants</label>
                    <div className="search-members search-courses">
                      <input
                        type="text"
                        name="searchMember"
                        className="form-control"
                        placeholder="Search events"
                        onChange={searchEvents}
                      />
                      <span className="search-icon">
                        <img src={searchicon} />
                      </span>
                    </div>
                    <div className="access d-flex flex-column mt-3">
                      {eventsList?.map(function (event) {
                        var checked = false;

                        const found = selectedEvents.find((ele) => {
                          if (ele === event._id) {
                            return true;
                          } else {
                            return false;
                          }
                        });

                        if (found) {
                          checked = true;
                        }
                        return (
                          <div className="access-level mb-1" key={event._id}>
                            <span className='float-left text-capitalize'>{event.name.length > 25 ? `${event.name.substring(0, 20)}...` : event.name}{` (${event.audience.length})`}</span>
                            <div className="custom-control custom-switch float-right">
                              <input
                                type="checkbox"
                                checked={checked}
                                className="custom-control-input"
                                id={`event-${event._id}`}
                                name={`event-${event._id}`}
                                onChange={() => selectEventMember(event)}
                              />
                              <label
                                className="custom-control-label cursor-pointer"
                                htmlFor={`event-${event._id}`}
                              />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 d-flex justify-content-evenly pr-2 flex-wrap mt-4">
                  <div className="scroll">
                    <label>Individuals (members)</label>
                    <div className="search-members search-courses">
                      <input
                        type="text"
                        name="searchMember"
                        className="form-control"
                        placeholder="Search members"
                        onChange={searchMembers}
                      />
                      <span className="search-icon">
                        <img src={searchicon} />
                      </span>
                    </div>
                    <div className="access d-flex flex-column mt-3">
                      {individualData}
                    </div>
                  </div>
                  <div className="scroll">
                    <label>Subscribers (these are leads)</label>
                    <div className="search-members search-courses">
                      <input
                        type="text"
                        name="searchMember"
                        className="form-control"
                        placeholder="Search subscriber"
                        onChange={searchSubscribers}
                      />
                      <span className="search-icon">
                        <img src={searchicon} />
                      </span>
                    </div>
                    <div className="access d-flex flex-column mt-3">
                      {subscribersData}
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </section>

          <section className="d-flex p-3">
            <div className="content-fluid mt-4 p-3 wis-modal w-75">
              <form name="form" onSubmit={submitForm}>
                <div className="form-group">
                  <label>
                    Subject
                    <small> Max 500 Characters</small>
                  </label>
                  <input
                    type="text"
                    value={subject}
                    // required
                    maxLength={500}
                    name="subject"
                    className="form-control"
                    onChange={(event) => {
                      setSubject(event.target.value);
                    }}
                  />
                </div>

                <div className="form-group">
                  <label>
                    Message
                  </label>
                  <SunRichTextEditor
                    value={msgContent}
                    handlSunRichtextEditorChange={handlSunRichtextEditorChange}
                  />
                </div>

                <div className="form-group">
                  <div className="d-flex">
                    <label>Schedule</label>
                    <div className="custom-control custom-switch ml-3">
                      <input
                        type="checkbox"
                        checked={isSchedule}
                        className="custom-control-input"
                        id="emailSchedulesSwitch"
                        name="emailSchedule"
                        onChange={() => {
                          setIsSchedule(prev => !prev);
                        }}
                      />
                      <label
                        className="custom-control-label cursor-pointer"
                        htmlFor="emailSchedulesSwitch"
                      />
                    </div>
                  </div>
                </div>

                {isSchedule && (
                  <div className="row d-flex align-items-center">
                    <div className="form-group cursor-pointer col-4">
                      <DatePicker
                        selected={date}
                        onChange={handleScheduleDateChange}
                        name="startDate"
                        dateFormat="yyyy-MM-dd"
                        className="form-control date cursor-pointer"
                        minDate={moment().toDate()}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group cursor-pointer col-4">
                      <DatePicker
                        placeholderText="Time"
                        value={time}
                        onChange={(date) => {
                          let timeValue = moment(date).format("HH:mm");
                          setTime(timeValue);
                        }}
                        name="time"
                        dateFormat="HH:mm"
                        className="form-control time cursor-pointer"
                        showTimeSelect={true}
                        showTimeSelectOnly
                        autoComplete="off"
                      />
                    </div>
                    <p className="col-2">{getGmtOffset()}</p>
                  </div>
                )}

                <div className="form-group w-50">
                  <label>
                    Attach Resource(s){" "}
                    <img src={infoIcon} width="16" className="mx-2" />
                    <small>
                      Add a new file direct from your device in PDF, Video,
                      Audio, Word doc, Excel Doc format.
                    </small>
                  </label>
                  <div className="position-relative">
                    <input
                      type="file"
                      className="form-control direct-add-file px-0 h-100"
                      onChange={onResourcesChange}
                      name="attachments"
                      value={attachmentsName}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="d-flex gap-3">
                    {isSchedule ? (
                      <button
                        type='submit'
                        value='schedule'
                        className="email-btn-custom"
                      >
                        Save Schedule
                      </button>
                    ) :
                      (
                        <button type='submit' className="email-btn-custom" value='save'>
                          Send
                        </button>
                      )}

                  </div>
                </div>
              </form>
            </div>
            <div className="content-fluid mt-4 p-1 wis-modal w-25 border-left border-top text-center">
              <label className="fst-italic">Selected Recipients ({renderedData?.length})</label>
              <div className="access d-flex flex-column" style={{ height: "auto", maxHeight: "750px" }}>
                {renderedData}
                {/* <button onClick={submitSubscriber}>subscribe</button> */}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default CreateEmail;