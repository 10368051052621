import React from "react";
import NewSidebar from "../new-sidebar-preview";

import events from "../../images/enduser/events.svg";
import socialwals from "../../images/enduser/social wall.svg";
import library from "../../images/enduser/library.svg";
import challenges from "../../images/enduser/challenges.svg";
import courses from "../../images/enduser/courses.svg";
import multimedia from "../../images/enduser/multimedia.svg";

const PreviewHub = () => {
  var bgcolor = localStorage.getItem("bgcolor");
  var imgs = localStorage.getItem("image");
  var membershipName = localStorage.getItem("membershipName");
  const bColor = bgcolor + 'b3';
  const button = {
    boxShadow: "0px 5px 6px " + bgcolor,
    border: "1px solid " + bgcolor,
  };
  return (
    <>
      <div
        style={{
          pointerEvents:"none"
        }}
      >
        <NewSidebar />
        <div className="content-wrapper enduser-dashboard">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-4">
                <div className="col">
                  <h2>HOME</h2>
                  <small>Welcome, {membershipName}!</small>
                </div>
              </div>
            </div>
          </div>
          <section
            className="explore-box"
            style={{ backgroundColor: bgcolor, opacity: "0.8" }}
          >
            <div className="company-info d-flex justify-content-start align-items-center border-0">
              <img src={imgs} className="company-img mr-2 img-fluid" alt="" style={{borderRadius: "50%",width:"150px",border:"1px solid transparent"}}/>
              <p className="company-name">
                {membershipName} <br />
                <small>Your Membership</small>
              </p>
            </div>
            <p className="text-white fw-400">Explore content in your membership in multiple ways below - Select the area you wish to visit below</p>
            {/* <a href="#" className="explore-btn" style={button}>
              Explore
            </a> */}
            <br />
            <span>
              <i className="fas fa-arrow-down" />
            </span>
          </section>
          <section className="info-card">
            <div className="d-flex align-items-center justify-content-left flex-wrap">
                <div className="card">
                <div className="card-body">
                    <div className="img-border mx-auto d-block" style={{borderColor: bColor}}>
                    <div className="img-bg" style={{backgroundColor: bgcolor}}>
                        <img src={events} className="card-img-top" alt="" />
                    </div>
                    </div>
                    <h5 className="info-title">Events</h5>
                </div>
                </div>
                <div className="card">
                <div className="card-body">
                    <div className="img-border mx-auto d-block" style={{borderColor: bColor}}>
                    <div className="img-bg" style={{backgroundColor: bgcolor}}>
                        <img src={socialwals} className="card-img-top" alt="" />
                    </div>
                    </div>
                    <h5 className="info-title">Social Wall</h5>
                </div>
                </div>
                <div className="card">
                <div className="card-body">
                    <div className="img-border mx-auto d-block" style={{borderColor: bColor}}>
                    <div className="img-bg" style={{backgroundColor: bgcolor}}>
                        <img src={library} className="card-img-top" alt="" />
                    </div>
                    </div>
                    <h5 className="info-title">Library</h5>
                </div>
                </div>
                <div className="card">
                <div className="card-body">
                    <div className="img-border mx-auto d-block" style={{borderColor: bColor}}>
                    <div className="img-bg" style={{backgroundColor: bgcolor}}>
                        <img src={challenges} className="card-img-top" alt="" />
                    </div>
                    </div>
                    <h5 className="info-title">Challenges</h5>
                </div>
                </div>
                <div className="card">
                <div className="card-body">
                    <div className="img-border mx-auto d-block" style={{borderColor: bColor}}>
                    <div className="img-bg" style={{backgroundColor: bgcolor}}>
                        <img src={courses} className="card-img-top" alt="" />
                    </div>
                    </div>
                    <h5 className="info-title">Courses</h5>
                </div>
                </div>
                <div className="card">
                <div className="card-body">
                    <div className="img-border mx-auto d-block" style={{borderColor: bColor}}>
                    <div className="img-bg" style={{backgroundColor: bgcolor}}>
                        <img src={multimedia} className="card-img-top" alt="" />
                    </div>
                    </div>
                    <h5 className="info-title">Multimedia</h5>
                </div>
                </div>
            </div>
        </section>
        </div>
      </div>
    </>
  );
};

export default PreviewHub;
