import { Component } from 'react';
import { Col, Container, Form, Row } from "react-bootstrap";
import { moduleList, userInfo } from "../../helper/service";

export default class Membershipsdeliverable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userData: '',
      allModule: [],
      modulesData: '',
    };
  }


  async componentDidMount() {
    const userId = localStorage.getItem('user_id');
    await userInfo(userId).then(data => {
      //console.log("data===============", data);

      if (
        data?.result?.validUser?.subscriptionId &&
        data?.result?.validUser?.subscriptionId?.length > 0 &&
        data?.result?.validUser?.subscriptionId[0]?.subsId?.modules
      ) {

        //console.log("Allowed Modules = ", data.result.validUser.subscriptionId[0].subsId.modules);
        this.setState({
          allowedModule: data?.result?.validUser?.subscriptionId[0]?.subsId?.modules,
        });
      }
      else {
        this.setState({
          allowedModule: [],
        });
      }
    })
    this.getAllModule();
  }

  async getAllModule() {
    await moduleList().then(data => {
      var datas = data.result.map(function (subs, key) {
        const isObjectPresent = this.state.allowedModule.find((o) => o.moduleId === subs._id);
        if (isObjectPresent) {
          return (
            <div className='member-box' key={Math.random()}>
              <div className='float-right'>
                <label className='mr-3'>Live</label>
                <Form.Check
                  type='switch'
                  id={Math.random()}
                  checked="true"
                  className='float-right'
                />
              </div>
              <div className='clearfix'></div>
              <img src={subs.image}></img>
              <h3 className='box-title'>{subs.name}</h3>
            </div>
          )
        } else {
          return (
            <div className='member-box' key={Math.random()}>
              <div className='float-right'>
                <label className='mr-3'>Hide</label>
                <Form.Check
                  type='switch'
                  disabled="true"
                  id={Math.random()}
                  className='float-right'
                />
              </div>
              <div className='clearfix'></div>
              <img src={subs.image}></img>
              <h3 className='box-title'>{subs.name}</h3>
            </div>
          )
        }

      }, this)
      this.setState({ modulesData: datas })
    })
  }


  render() {

    return (
      <div>
        <Container fluid>
          <Row>
            <Col xl={8} lg={6} md={7}>
              <h2 className='title'>HUB Settings
                {/* <a href='#'>Return to Main Library</a> Membership settings in h2 */}
              </h2>
            </Col>
          </Row>
            {/* <Col xl={4} lg={6} md={5}>
                  <div className='calendar'>
                    <Image src={membershipCalendar}></Image>
                    <input type='text' name className='form-control' placeholder='12 Sep, 20 - 22 Sep, 20' />
                  </div>
                </Col> */}
          {/* </Row> */}
          {/* <Row> */}
            {/* <Col xl={12} className='d-flex'> */}
              {/* <h2 className='sub-title'>Your membership deliverables </h2> */}
              {/* <Image src={error} fluid width={24} height={24} className='mx-3'></Image> */}
              {/* <Image src={angleTop} fluid width={24} height={24}></Image> */}
            {/* </Col> */}
          {/* </Row> */}
          {/* deliverables box */}
          {/* <Row> */}
            {/* <Col xl={12}> */}
              {/* <div className='d-flex justify-content-left text-center flex-wrap deliverables-box'> */}
                {/* {this.state.modulesData} */}
                {/* <div className='member-box'>
                      <div className='float-right'>
                        <label className='mr-3'>Live</label>
                        <Form.Check 
                          type='switch'
                          id='custom-switch'
                          className='float-right'
                          defaultChecked                        
                        />
                      </div>
                      <div className='clearfix'></div>
                      <img src={eventIcon}></img>
                      <h3 className='box-title'>Events</h3>
                    </div> */}

                {/* <div className='member-box'>
                      <div className='float-right'>
                        <label className='mr-3'>Live</label>
                        <Form.Check 
                          type='switch'
                          id='custom-switch1'
                          className='float-right'
                          defaultChecked                        
                        />
                      </div>
                      <div className='clearfix'></div>
                      <img src={wallIcon}></img>
                      <h3 className='box-title'>Social Wall</h3>
                    </div>

                    <div className='member-box'>
                      <div className='float-right'>
                        <label className='mr-3'>Live</label>
                        <Form.Check 
                          type='switch'
                          id='custom-switch2'
                          className='float-right'
                          defaultChecked                        
                        />
                      </div>
                      <div className='clearfix'></div>
                      <img src={challengesIcon}></img>
                      <h3 className='box-title'>Challenges</h3>
                    </div>
                    <div className='member-box hide'>
                      <div className='float-right'>
                        <label className='mr-3'>Hide</label>
                        <Form.Check 
                          type='switch'
                          id='custom-switch3'
                          className='float-right'
                        />
                      </div>
                      <div className='clearfix'></div>
                      <img src={courseIcon}></img>
                      <h3 className='box-title'>Courses</h3>
                    </div>
                    <div className='member-box'>
                      <div className='float-right'>
                        <label className='mr-3'>Live</label>
                        <Form.Check 
                          type='switch'
                          id='custom-switch4'
                          className='float-right'
                          defaultChecked                        
                        />
                      </div>
                      <div className='clearfix'></div>
                      <img src={contentIcon}></img>
                      <h3 className='box-title'>Content Library</h3>
                    </div>
                    <div className='member-box'>
                      <div className='float-right'>
                        <label className='mr-3'>Live</label>
                        <Form.Check 
                          type='switch'
                          id='custom-switch5'
                          className='float-right'
                          defaultChecked                        
                        />
                      </div>
                      <div className='clearfix'></div>
                      <img src={videoIcon}></img>
                      <h3 className='box-title'>Video Library</h3>
                    </div>
                    <div className='member-box'>
                      <div className='float-right'>
                        <label className='mr-3'>Live</label>
                        <Form.Check 
                          type='switch'
                          id='custom-switch6'
                          className='float-right'
                          defaultChecked                        
                        />
                      </div>
                      <div className='clearfix'></div>
                      <img src={audioIcon}></img>
                      <h3 className='box-title'>Audio Library</h3>
                    </div> */}
                {/* <div className='member-box'>
                      <div className='float-right'>
                        <label className='mr-3'>Live</label>
                        <Form.Check 
                          type='switch'
                          id='custom-switch7'
                          className='float-right'
                          defaultChecked                        
                        />
                      </div>
                      <div className='clearfix'></div>
                      <img src={photoIcon}></img>
                      <h3 className='box-title'>Photo Library</h3>
                    </div> */}
              {/* </div>
            </Col> */}
          
        </Container>
      </div>

    )
  }
}
