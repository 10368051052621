import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import Header from '../Common/Header'
import NewSidebar from '../new-sidebar'
import EmailList from './EmailList';
import UnsubscribedListModal from './UnsubscribedListModal';
import SetEmailAddress from './SetEmailAddress';
import ConfirmationModal from '../Setting/ConfirmationModal';

import infoIcon from "../../images/dashboard/ic_error.svg";
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SuccessPopup from '../Setting/Sucesspopup';
import { userInfo } from '../../helper/service';
import {saveEmailAutomation} from "../../helper/service.ts";
import { verifyEmail } from '../../services/settingsService';
import AttentionBanner from '../Common/AttentionBanner';
import Gauge from '../Common/Limit/Gauge';
import GoBackButton from '../Common/Buttons/GoBackButton';

const settingTip = 'Wisdome has a built-in email autoresponder that you can use to send email. When members reply to these emails you send out, they will arrive into your third-party email provider';

const Email = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isSetupOpen, setOpenSetup] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState(false);

    const [verificationMessage, setVerificationMessage] = useState('');
    const [showVerification, setShowVerification] = useState(false);
    const [phone, setPhone] = useState("");
    const [website, setWebsite] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [hasLimits, setHasLimits] = useState(false);
    const [allowedEmails, setAllowedEmails] = useState(0);
    const [usedEmails, setUsedEmails] = useState(0);

    const handleGauge = (allowed, used) => {
        //console.log(allowed, used, "gauge");
        setHasLimits(true);
        setAllowedEmails(allowed);
        setUsedEmails(used);
    }

    const resetState = () => {
        setConfirmEmail(false);
        setShowVerification(false);
    }

    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        userInfo(userId).then(data => {
            if (data?.result?.validUser) {
                let user = data?.result?.validUser;
                if (user.emailSenderVerified) {
                    setShowCreate(true);
                    setIsVerified(true);
                } else {
                    setShowCreate(false);
                    setIsVerified(false);
                }
                let company = user.company && user.company.length > 0 ? user.company[0] : {};
                setEmail(company?.email);
                setPhone(company?.contactNumber);
                setWebsite(company?.websiteURL);
            }
            setIsLoading(false);
        }
        )
    }, []);

    if (isLoading) {
        return (
            <>
                <NewSidebar />
                <div>Loading...</div>
            </>
        );
    }

    const handleVerification = () => {
        setConfirmEmail(false);
        verifyEmail().then(result => {
            console.log(result, "data");
            if (result.status === 200) {
                setShowVerification(true);
                setVerificationMessage(result?.data?.message ?? "Error while verifying email, please try again later");
                if (result?.data?.success) {
                    setShowCreate(false);
                }
            } else {
                setVerificationMessage("Error while verifying email, please try again later");
            }
        })
    }
    const saveForAutomation = async () => {
        setIsLoading(true);
        try {
            const res = await saveEmailAutomation({});
            if (res.message === "Automation saved successfully") {
                navigate(`/email-automation/${res.result._id}`);
            }
        } catch (err) {
            console.log(err, "Error Saving automation");
        } finally {
            setIsLoading(false);
        }
    };    

    return (
        <>
            {showVerification && <SuccessPopup message={verificationMessage} onCloseSuccessModel={() => resetState()} />}
            {(email && website && phone) ? (
                <>
                    <Header />
                    <NewSidebar />
                </>
            ) : null}

            {(!email || !website) ? (
                <>
                    <Header />
                    <NewSidebar />
                    <AttentionBanner message="Email messaging is not available! Please update company details." link="/settings/company" linkText="Update" />
                </>
            ) : (
                <div className="content-wrapper admin-courses bg-white for-drop-down">
                    <div className='content-header mb-4'>
                        <section className='email-messaging'>
                            <GoBackButton />
                            <div className='content-fluid px-3 py-2'>
                                <div className='row mt-4 p-2'>
                                    <div className='col-lg-6 col-md-8 col-sm-10'>
                                        <h3 className='title'>Email Messaging</h3>
                                        <div className='text'>
                                            <p>
                                                Deliver messages to your members' email inbox. Members will receive emails from <b>your company email address.</b>
                                                <span>Your email: <b>{email}</b></span>
                                            </p>
                                        </div>

                                        <div className='d-flex align-items-center flex-wrap gap-1 autoresponder'>
                                            {!isVerified && (
                                                <Link
                                                    className='last-action mr-3'
                                                    onClick={() => {
                                                        setConfirmEmail(true);
                                                    }}>
                                                    Click here to confirm your Email Address
                                                </Link>
                                            )}

                                            {showCreate && (
                                                <div className='dropdown mr-4 w-25'>
                                                    <button type="button" className='email-btn-custom w-100 dropdown-toggle' id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                        <FontAwesomeIcon icon={faPlus} /> Create
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{minWidth:"135px",width:"135px"}}>
                                                        <Link to='/create-email' className="dropdown-item w-100" style={{fontSize:"13px",padding:"5px 15px"}}>Email</Link>
                                                        <Link to='/create-email-template' className="dropdown-item w-100" style={{fontSize:"13px",padding:"5px 15px"}}>Template</Link>
                                                        <span className="dropdown-item w-100" style={{fontSize:"13px",padding:"5px 15px",cursor:"pointer"}} onClick={saveForAutomation}>Email Automation</span>
                                                    </div>
                                                </div>
                                            )}

                                            <button type="button"
                                                className='email-btn-custom w-25'
                                                onClick={() => {
                                                    navigate('/email-setting');
                                                }}
                                            >
                                                Settings
                                            </button>

                                            <Tooltip title={settingTip} placement="top-start">
                                                <span className='info-img'>
                                                    <img src={infoIcon} width={20} />
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-8 col-sm-10'>
                                        {hasLimits && <Gauge message="Emails Allowed per month" allowedText="Allowed" usedText="Used" allowed={allowedEmails} used={usedEmails} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    {isVerified && (
                        <div>
                            {isSetupOpen ? (
                                <SetEmailAddress onClose={() => resetState()} />
                            ) : (
                                <section className="courses-list">
                                    <EmailList prepareGauge={handleGauge} />
                                </section>
                            )}

                            {isOpen && <UnsubscribedListModal onClose={() => setIsOpen(false)} />}
                        </div>
                    )}

                    {confirmEmail && (
                        <ConfirmationModal
                            type='verify'
                            title="Confirm your new sender email address"
                            message={email}
                            onCancel={() => resetState()}
                            onConfirm={handleVerification}
                        />
                    )}
                </div>
            )}
        </>
    )
}

export default Email