import React, {useEffect} from 'react'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

const SunRichTextEditor = ({ handlSunRichtextEditorChange, value }) => {
  // const handlChange=(content)=>{
  //     console.log("handle change",content)
  //     console.log("handle change",content.length)
  // }
  //  const handleImageUpload=(targetImgElement, index, state, imageInfo, remainingFilesCount)=>{
  //       console.log(targetImgElement, index, state, imageInfo, remainingFilesCount)
  //   }
  useEffect(() => {
    const editor = document.querySelector('.sun-editor-editable');
    if (editor) {
      editor.style.fontSize = '16px';
    }
  }, []);
  return (
    <div>

      <SunEditor setAllPlugins={true}
        //onImageUpload={handleImageUpload}
        setContents={value}
        width='100%' height='200' autoFocus={false}
        onChange={handlSunRichtextEditorChange} 
        setOptions={{
          buttonList: [[
            'undo', 'redo', 'bold', 'underline', 'italic', 'strike', 'list', 'align', 'font', 'fontSize', 'formatBlock', 'table', 'image', 'video', 'audio', 'codeView', 'fullScreen',
            'preview', 'print', 'lineHeight', 'link', 'hiliteColor', 'paragraphStyle', 'textStyle',

          ]]
        }} />
    </div>
  )
}

export default SunRichTextEditor
