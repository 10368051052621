import React from "react";
import {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { useNotification } from "../context/NotificationContext";
import adminImage from "../../images/adminImage.png";
import { Link } from "react-router-dom";
import {faSquareCheck,faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material'
function NotificationDropDown() {
    const [isOpen,setOpen]=useState(false);
    const {notifications, removeNotification}=useNotification();
    const navigate = useNavigate();

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    const uniqueNotification = Array.from(new Set(notifications.map(obj => obj._id)))
        .map(id => notifications.find(obj => obj._id === id))
        .filter(notification => new Date(notification.createdAt) >= sixMonthsAgo);
    

    const totalNotifications = uniqueNotification?.filter(obj => obj.status === 'Queued').length?? 0;
    document.title=`(${totalNotifications}) Wisdome - Partner`
    const handleClick=(e,id)=>{
        e.stopPropagation();
        removeNotification(id);
        setOpen(true)
    }
    const toggleDropdown = () => {
        setOpen(!isOpen);
    };
    

    return (

        <div className={`nav-item dropdown ${isOpen? 'show': ''}`} onClick={toggleDropdown}>
            <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="far fa-bell" />
                {totalNotifications > 0 && <span className="badge badge-warning navbar-badge">{totalNotifications}</span>}
            </a>
            {
                <div className="notification dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <div className="notification-box">
                        <span className="dropdown-item dropdown-header">
                            Notifications
                        </span>
                        <Link to='/notifications' className="dropdown-item dropdown-header">
                         See All Notifications
                        </Link>
                    </div>
                    <div className="dropdown-divider" />

                    {uniqueNotification.length > 0 ? (uniqueNotification.slice(0, 5).map((notification, index) => {
                        const createdAt = new Date(notification.createdAt);
                        const currentTime = new Date();

                        // Calculate the time difference in milliseconds
                        const timeDifference = currentTime - createdAt;

                        const minutesDifference = Math.floor(timeDifference / (1000 * 60));
                        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                        const weeksDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));

                        let hour, minute, day, weeks;

                        if (!hoursDifference && minutesDifference > 0) {
                            minute = minutesDifference
                        }
                        else if (!daysDifference && hoursDifference > 0) {
                            hour = hoursDifference;
                        }
                        else if (!weeksDifference && daysDifference > 0) {
                            day = daysDifference
                        }
                        else if (weeksDifference) {
                            weeks = weeksDifference;
                        }
                        return (
                            <div key={notification._id}>
                                <a href="#" className="dropdown-item hover-color">
                                    <div className="notification-container">
                                        <div className="w-100 position-relative">
                                            <div className="notification-content">
                                                <span>
                                                    <strong className={notification.status==='Read'?'text-muted':''}>
                                                        {notification.webContent.title}
                                                    </strong>
                                                </span>
                                                <span className={notification.status !== 'Read' ?'time':''}>
                                                    <small className="text-muted text-sm">
                                                        {weeks > 0 ? weeks + " week ago" : day > 0 ? day + " day ago" : hour > 0 ? hour + " hour ago" : minute > 0 ? minute + " min ago" : "Just now"}
                                                    </small>
                                                </span>
                                            </div>
                                            <div className="d-flex gap-1 for-dropdown">
                                                {notification.status!=='Read' &&
                                                ( 
                                                    <Tooltip title="Mark as Read" placement="bottom">
                                                        <div className="mark-as-read" type="button" name="read" onClick={(event)=>{handleClick(event,notification._id)}}>
                                                            <FontAwesomeIcon icon={faSquareCheck} width={22} height={22}/>
                                                        </div>
                                                    </Tooltip>
                                                )}
                                                {notification.webContent.link && 
                                                (
                                                    <Tooltip title="View" placement="bottom">
                                                        <div className="mark-as-read" onClick={()=>navigate(notification.webContent.link)} >
                                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} width={22} height={22}/>
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </div>
                                            <p style={{ fontSize: "0.85rem" }}> {notification.webContent.description}</p>
                                        </div>
                                    </div>
                                </a>
                                <div className="dropdown-divider" />
                            </div>
                        )
                    })):<div className='d-flex align-items-center justify-content-center my-2'>No New Notification to Show</div>}

                </div>
            }
        </div>
    );
}

export default NotificationDropDown;
