import React, { useState } from 'react'
import { Modal} from "react-bootstrap";
import close from '../../images/dashboard/modal-close.svg';
import AttentionBanner from '../Common/AttentionBanner';

export default function UnsuccessPopup(props) {
    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        props.setIsSuccess(false);
        setIsOpen(false);
    }
    return (
        <Modal className="success-modal" show={isOpen} onHide={closeModal} backdrop="static">
            <Modal.Header className="justify-content-end align-items-end border-0 pb-0">
                <button onClick={closeModal} type="button" className="close ml-5 z-index-3" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><img src={close} /></span>
                </button>
            </Modal.Header>
            <Modal.Body className="text-center">
                <AttentionBanner message={props.message} link={props.link} linkText={props.linkText} />
            </Modal.Body>
        </Modal>
    )
}
