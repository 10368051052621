import React from "react";
import NewSidebar from "../components/new-sidebar";
import Header from "../../src/components/Common/Header";
import calendar from "../../src/images/dashboard/calendar.svg";
import BodyClassName from "react-body-classname";
import { userInfo } from "../helper/service";
import { commonUtil } from "../helper/CommonUtils";
import UsersChart from "./UsersChart";
import UpcomingEvents from "./events/UpcomingEvents";
import ConfirmationModal from "./Setting/ConfirmationModal";

import copyIcon from "../images/copy1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";

export interface DashboardProps { }

export interface DashboardState {
  firstName?: string;
  lastName?: string;
  salesPageUrl: string;
  currentDay: number;
  currentDate: Date;
  message?: string;
  warningModalTitle?: string;
  modalType?: string;
  showWarningModal?: Boolean;
  isCopy:Boolean,
}

export default class Dashboard extends React.Component<
  DashboardProps,
  DashboardState
> {
  constructor(props: any) {
    super(props);
    const currentDate = new Date();
    this.state = {
      firstName: "",
      lastName: "",
      salesPageUrl: "",
      currentDay: currentDate.getDay(),
      currentDate: currentDate,
      message: '',
      warningModalTitle: '',
      modalType: '',
      showWarningModal: false,
      isCopy:false,
    };
  }

  componentDidMount() {
    const userId = localStorage.getItem("user_id");
    if (userId === null) return window.location.replace("/login");
    const url = `${commonUtil.MEMBERURL()}/sales/${userId}`;
    this.setState({ salesPageUrl: url });
    const query = new URLSearchParams(window.location.search);

    let id = query.get("userId");
    try {
      userInfo(userId).then((data) => {
        this.setState({
          firstName: data.result.validUser.firstName,
          lastName: data.result.validUser.lastName,
        });
      });
    } catch (err) {
      this.setState({ 
        message:"Error. Something went wrong.",
        warningModalTitle:"ERROR!!!",
        modalType:'error',
        showWarningModal:true,
      });
    }
  }

  getDayName(dayIndex: number): string {
    const dayNames: string[] = [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat",
    ];
    return dayNames[dayIndex];
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  render() {
    const daysToShow: number = 7;
    const dayElements: JSX.Element[] = [];

    for (let i = 0; i < daysToShow; i++) {
      const dayDate = new Date(this.state.currentDate);
      dayDate.setDate(dayDate.getDate() + i);

      dayElements.push(
        <div key={i} className="mb-2">
          <div className={`day ${this.isToday(dayDate) ? "active" : ""}`}>
            <span>{dayDate.getDate()}</span>
            <br />
            <span>{this.getDayName(dayDate.getDay())}</span>
          </div>
        </div>
      );
    }
    return (
      <BodyClassName className="">
        {/* className="sidebar-collapse" */}
        <div className="wrapper">
          <Header />
          <NewSidebar />
          {this.state.showWarningModal && (
            <ConfirmationModal
              type={this.state.modalType}
              title={this.state.warningModalTitle}
              message={this.state.message}
              onConfirm={() => {
                this.setState({
                  showWarningModal: false,
                  message: '',
                  warningModalTitle: '',
                  modalType: '',
                })
              }}
              onCancel={() => this.setState({ showWarningModal: false, message: '', warningModalTitle: '', modalType: '' })}
            />
          )}
          <div className="content-wrapper bg-white">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-4">
                  <div className="col-sm-4 dashboard-welcome">
                    <h1>
                      Dashboard <small>Welcome {this.state.firstName}</small>
                    </h1>
                  </div>
                  <div className="col-xl-6">
                    <div
                      className="download-link"
                      style={{
                        backgroundColor: "#8fd0d5",
                        borderRadius: "0.3em",
                      }}
                    >
                      <h5
                        className="mr-l"
                        style={{
                          margin: "0",
                          padding: "0.5em 0 0 0.5em",
                          color: "#fff",
                        }}
                      >
                        YOUR SALES PAGE
                      </h5>
                      <div className='d-flex justify-content-between align-items-center gap-2' style={{ margin: "0", padding: "0.5rem 1rem", cursor: "pointer"}}>
                      <span style={{fontSize:'14px', color: "#fff"}}>
                        {this.state.salesPageUrl}
                      </span>
                      <span className='d-flex gap-2'>
                        <Tooltip title="Copy" placement='bottom'>
                          <img 
                            src={copyIcon} style={{height:"17px"}} 
                            onClick={() => {navigator.clipboard.writeText(this.state.salesPageUrl);this.setState(prevState => ({ isCopy: !prevState.isCopy }))}}
                          />
                        </Tooltip>
                        <Tooltip title="View" placement='bottom'>
                          <FontAwesomeIcon 
                            icon={faArrowUpRightFromSquare} style={{height:"16px",color:'#fff'}} onClick={() => window.open(this.state.salesPageUrl, "_blank")}
                          />
                        </Tooltip>
                      </span>
                      </div>
                    </div>{this.state.isCopy && <p style={{marginLeft:"1rem",fontSize:'x-small'}}>Sales page url copied to clipboard</p>}
                  </div>
                  {/* <div className="col-xl-4">
                      <div className="daterange d-flex justify-content-end mt-4">
                        <img src={calendar} width={52} height={52} />
                        <input type="text" className="ml-2 form-control bg-white" id="daterange" placeholder="12 Sep, 20 - 22 Sep, 20"/>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-xl-6">
                  <div className="card mb-5 calendar">
                    <div className="card-header">
                      <h3 className="card-title pt-3">Calendar</h3>
                      <div className="card-tools">
                        <img src={calendar} width={52} height={52} />
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-center  text-center flex-wrap">
                        {dayElements}
                      </div>
                    </div>
                  </div>
                  <div className="card events mb-5 ">
                    <UpcomingEvents />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="card active-users mb-5">
                    <div className="card-header">
                      <h3 className="card-title">Users</h3>
                      {/* <div className="card-tools">
                        More Stats <i className="fas fa-angle-right" />
                      </div> */}
                    </div>
                    <div className="card-body">
                      <div className="chart">
                        {/* <canvas
                          id="barChart"
                          style={{
                            minHeight: "250px",
                            height: "250px",
                            maxHeight: "250px",
                            maxWidth: "100%",
                          }}
                        /> */}
                        <UsersChart />
                      </div>
                    </div>
                  </div>
                  {/* <div className="card activity mb-5">
                    <div className="card-header border-0">
                      <h3 className="card-title">Activity Stream</h3>
                      <div className="card-tools">
                        View all <i className="fas fa-angle-right" />
                      </div>
                    </div>
                    <div className="card-body"> */}
                  {/* <div className="activity-list">
                          <div className="media">
                            <img src={photo} className="img-rounded mr-3" alt="activity" />
                            <div className="media-body">
                              <h5 className="mt-0">Oscar Holloway</h5>
                              <p>UI/UX Designer</p>
                            </div>
                          </div>
                          <div className="media activity-status">
                            <img src={upload} className="mr-3" alt="activity" />
                            <div className="media-body">
                              <p>Updated the status of Mind Map task to In Progress</p>
                            </div>
                          </div>
                          <div className="media activity-status">
                            <img src={attach} className="mr-3" alt="activity" />
                            <div className="media-body">
                              <p>Attached files to the task</p>
                            </div>
                          </div>
                        </div>
                        <div className="activity-list">
                          <div className="media">
                            <img src={photo1} className="img-rounded mr-3" alt="activity" />
                            <div className="media-body">
                              <h5 className="mt-0">Emily Tyler</h5>
                              <p>Copywriter</p>
                            </div>
                          </div>
                          <div className="media activity-status">
                            <img src={upload} className="mr-3" alt="activity" />
                            <div className="media-body">
                              <p>Updated the status of Mind Map task to In Progress</p>
                            </div>
                          </div>
                        </div> */}
                  {/* </div>
                  </div>
                </div> */}
                  {/* <div className="col-xl-4">
                    <div className="card messages mb-5">
                      <div className="card-header border-0">
                        <h3 className="card-title pt-3">Messages</h3>
                        <div className="card-tools">
                          <img src={filter} className="img-fluid mr-2" />
                          <img src={plus} className="img-fluid" />
                        </div>
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="form-group">
                            <input type="search" name="search" className="form-control search" placeholder="Search" />
                          </div>
                        </form>
                        <h3 className="sub-title">Messages</h3> */}
                  {/* <div className="messages-list">
                          <div className="media">
                            <img src={genphoto5} className="img-rounded mr-3" alt="activity" />
                            <div className="media-body">
                              <h5 className="mt-0 messanger-name">Garrett Watson <span className="float-right msg-time">12:04</span></h5>
                              <p className="messanger-text">Hi! Please, change the statu... <span className="float-right msg-count">12</span></p>
                            </div>
                          </div>
                          <div className="media">
                            <img src={photo1} className="img-rounded mr-3" alt="activity" />
                            <div className="media-body">
                              <h5 className="mt-0 messanger-name">Caroline Santos <span className="float-right msg-time">12:04</span></h5>
                              <p className="messanger-text">Hi! Please, change the statu... <span className="float-right msg-count">12</span></p>
                            </div>
                          </div>
                          <div className="media">
                            <img src={genphoto2} className="img-rounded mr-3" alt="activity" />
                            <div className="media-body">
                              <h5 className="mt-0 messanger-name">Leon Nunez <span className="float-right msg-time">12:04</span></h5>
                              <p className="messanger-text">Hi! Please, change the statu... <span className="float-right msg-count">12</span></p>
                            </div>
                          </div>
                          <div className="media">
                            <img src={photo} className="img-rounded mr-3" alt="activity" />
                            <div className="media-body">
                              <h5 className="mt-0 messanger-name">Oscar Holloway <span className="float-right msg-time">12:04</span></h5>
                              <p className="messanger-text">Hi! Please, change the statu... <span className="float-right msg-count">12</span></p>
                            </div>
                          </div>
                          <div className="media">
                            <img src={genphoto3} className="img-rounded mr-3" alt="activity" />
                            <div className="media-body">
                              <h5 className="mt-0 messanger-name">Ralph Harris <span className="float-right msg-time">12:04</span></h5>
                              <p className="messanger-text">Hi! Please, change the statu... <span className="float-right msg-count">12</span></p>
                            </div>
                          </div>
                        </div> */}
                  {/* </div>
                    </div> */}
                </div>
              </div>
            </div>
            {/* Main content */}
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3 col-6"></div>
                </div>
                <div className="row">
                  <section className="col-lg-7 connectedSortable"></section>
                  <section className="col-lg-5 connectedSortable"></section>
                </div>
              </div>
            </section>
          </div>
        </div>
      </BodyClassName>
    );
  }
}
