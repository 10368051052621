class CommonUtils {
  redirect(url) {
    window.location.href = url;
  }

  BASEURL() {
    return process.env.REACT_APP_BACKEND_API_URL;
  }
  
  MEMBERURL() {
    return process.env.REACT_APP_MEMBER_URL;
  }
  
  PABBLYURL() {
    return process.env.REACT_APP_PABBLY_API_URL;
  }

  getHashedDigest(plainText) {
    const crypto = require("crypto");
    const hash = crypto.createHash("sha512");
    hash.update(plainText);
    return hash.digest("hex");
  }

  generateRandomKey() {
    return Math.random().toString(36).slice(2, 9);
  }

}

const commonUtil = Object.freeze(new CommonUtils());

function isImageFile(file) {
  return file && file['type'].split('/')[0] === 'image';
}

export { commonUtil, isImageFile };
