import React, { Component } from 'react'
import { Modal, Image } from "react-bootstrap";
import close from '../../images/dashboard/modal-close.svg';
import success from '../../images/audio/success.svg';

export default class SuccessPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
        };
    }

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => {
        this.setState({ isOpen: false });
        if(this.props.closeSuccessModel){
            this.props.closeSuccessModel();
        }
        if(this.props.reload){
        window.location.reload();
        }
    }

    render() {
        return (
            <Modal className="sucessPopup" id="playlistAddedModal" show={this.state.isOpen} onHide={this.closeModal} backdrop="static">
                <Modal.Header className="justify-content-end align-items-end border-0 pb-0">
                    <button onClick={this.closeModal} type="button" className="close ml-5" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img src={close} /></span>
                    </button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Image src={success} className="success-img img-fluid"></Image>
                    <h4 className='title'>{this.props.message}</h4>
                </Modal.Body>
            </Modal>
        )
    }
}