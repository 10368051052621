import { Link } from "react-router-dom";
export default function OverLimitNotice(props) {
    return (
        <div className="d-flex gap-2" style={{
            backgroundColor: "#ffc107",
            margin: "5px 5px",
            padding: "20px",
            borderRadius: "8px",
        }}>
            <div
                className="w-30 h-30 d-flex align-items-center rounded-3 p-1 my-3 text-white"
                style={{ backgroundColor: "rgb(255, 0, 0)", maxHeight: "30px" }}
            >
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-x-octagon-fill"
                    viewBox="0 0 16 16"
                >
                    <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                </svg>
            </div>
            <div className="d-flex align-items-center my-1" style={{ padding: "0 10px", fontWeight: 500 }}>
                <p className="f-6">{props.message}</p>
                {props?.source === "subscribers" && (
                    <Link to="/settings/subscribe-settings" className="btn btn-primary btn-sm ml-2">
                        Add Email Template
                    </Link>
                )}
            </div>
        </div>
    );
}