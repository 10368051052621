import React from 'react'
import Header from './Common/Header'
import NewSidebar from './new-sidebar'

const SubsExpired = () => {
  return (
    <div className='main-subs-expired-div'>
      <Header/>
      <NewSidebar/>
    <div className='expire-subs-div'>
      <p>Your Subscription is not valid. Please subscribe some plan.</p>
      <p>Visit settings page and subscribe a plan under account details tab.</p>
    </div>
    </div>
  )
}

export default SubsExpired
